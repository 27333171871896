// Dependencies
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import styled from "@emotion/styled";
import { RichText } from "prismic-reactjs";
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from "framer-motion";

// Styles
import colors from "styles/colors";
import dimensions from "styles/dimensions";

// Container is a link to the project page
const ProjectCardContainer = styled(Link)`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2em;
    transition: all 150ms ease-in-out;
    text-decoration: none;
    color: currentColor;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        flex-direction: column-reverse;
    }

    &:hover {
        box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.1);
        transition: all 150ms ease-in-out;

        // Animate arrow on container hover
        .ProjectCardAction {
            color: ${colors.bqblue500};
            transition: all 150ms ease-in-out;

            span {
                transform: translateX(0px);
                opacity: 1;
                transition: transform 150ms ease-in-out;
            }
        }
    }
`

// Text of the card
const ProjectCardText = styled("div")`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 2em;
    width: 50%;

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        width: 100%;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
        padding: 1.5em;
    }
`

// Everything but the details arrow
const ProjectCardContentUpper = styled("div")`
    display: flex;
    flex-direction: column;
    gap: 1em;

    // Category
    h6 {
        color: ${colors.grey500};
    }

    // Title
    h3 {
        margin: 0;
    }

    // Blurb
    p {
        margin: 0;
        line-height: 1.7;
    }
`

// Details arrow
const ProjectCardAction = styled("div")`
    font-size: 0.8em;
    font-weight: 600;
    
    text-decoration: none;

    color: currentColor;
    transition: all 150ms ease-in-out;
    
    margin-top: 2em;
    
    span {
        margin-left: 0.75em;
        transform: translateX(-6px);
        display: inline-block;
        transition: transform 300ms ease-in-out;
    }
`

// Image container
const ProjectCardImageContainer = styled("div")`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 2em 3em 2em 3em;
    width: 50%;

    @media (max-width: ${dimensions.maxwidthDesktop}px) {
        padding: 0em 2em;
    }

    @media (max-width: ${dimensions.maxwidthTablet}px) {
        padding: 2em 2em 0em 2em;
        width: 100%;
    }

    @media (max-width: ${dimensions.maxwidthMobile}px) {
    }

    img {
        width: 100%;
        box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
        transition: all 150ms ease-in-out;

        @media (max-width: ${dimensions.maxwidthTablet}px) {
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.13);
        }

        @media (max-width: ${dimensions.maxwidthMobile}px) {
            box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.10);
        }
    }
`

const variants = {
    hidden: {
        y: -10,
        opacity: 0      
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: {
            type: "tween",
            ease: "easeInOut"
        }
    }
};

const ProjectCardv2 = ({ category, title, description, thumbnail, uid }) => {
    
    const controls = useAnimation();
    const [ref, inView] = useInView();

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    return (
        <motion.div
            variants={variants}
            initial="hidden"
            animate={controls}
            ref={ref}
        >
            <ProjectCardContainer to={`/work/${uid}`}>
                
                <ProjectCardText>
                    <ProjectCardContentUpper>
                        <h6>{category[0].text}</h6>
                        
                        <h3>{title[0].text}</h3>
                        
                        {RichText.render(description)}
                    </ProjectCardContentUpper>
                    
                    <ProjectCardAction className="ProjectCardAction">
                        Details<span>&#8594;</span>
                    </ProjectCardAction>
                </ProjectCardText>
                
                {thumbnail && (
                    <ProjectCardImageContainer>
                        <img src={thumbnail.url} alt={title[0].text}/>
                    </ProjectCardImageContainer>
                )}
            
            </ProjectCardContainer>
        </motion.div>  
    );
};

export default ProjectCardv2;

// Ensure types for ProjectCard props
ProjectCardv2.propTypes = {
    category: PropTypes.array.isRequired,
    thumbnail: PropTypes.object.isRequired,
    title: PropTypes.array.isRequired,
    description: PropTypes.array.isRequired,
    uid: PropTypes.string.isRequired
}